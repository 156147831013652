


































































































































import {Component, Prop, VModel, Vue} from 'vue-property-decorator';
import Location from '@/models/Location';
import {namespace} from 'vuex-class';
import {locationStoreActions, locationStoreMutations} from '@/stores/location.store';

const LocationStore = namespace('location');

/**
 * Simple component that lists all customer in a table
 */
@Component({
  components: {
    LocationManageMasterDataComponent: () => import(
        /* webpackChunkName: "LocationManageMasterDataComponent" */
        '@/components/location/LocationManageMasterData.component.vue'),
    TableComponent: () => import(
        '@/components/shared/table/Table.component.vue'),
    ActionMenuComponent: () => import(
        '@/components/shared/table/ActionMenu.component.vue'),
  },
})
export default class LocationTableComponent extends Vue {

  @LocationStore.Action(locationStoreActions.LOAD_LOCATION_ACTION)
  public loadLocationAction!: (arg: { locationId: string, shouldBeStored: boolean }) => Promise<Location>;
  @LocationStore.Action(locationStoreActions.DELETE_LOCATION_ACTION)
  public deleteLocationAction!: (locationId: string) => void;
  @LocationStore.Action(locationStoreActions.SET_LOCATION_STATUS_ACTION)
  public setLocationStatusAction!: (payload: {location: Location}) => Promise<Location>;
  @LocationStore.Mutation(locationStoreMutations.UPDATE_LOCATION)
  public updateLocationMutation!: (location: Location | undefined) => void;
  @VModel({default: ''})
  public searchString!: string;
  @Prop({default: []})
  private locations!: Location[];
  private showLocationModal: boolean = false;
  private showDeleteDialog: boolean = false;
  private showChangeStatusDialog: boolean = false;
  private selectedLocation: Location | undefined = undefined;

  @LocationStore.Action(locationStoreActions.EDIT_LOCATION_ACTION)
  private editLocationAction!: (payload: { location: Location, shouldBeStored: boolean }) => Promise<Location>;

  constructor() {
    super();
    this.selectedLocation = new Location();
  }

  get importantKeys() {
    return {
      name: String,
      address: {street: String, houseNo: String, city: String, postalCode: String},
      contactPerson: {firstName: String, lastName: String},
    };
  }

  get isActive() {
    const inactive: string = (this.$t('GENERAL.INACTIVE') as string).toLowerCase();
    const active: string = (this.$t('GENERAL.ACTIVE') as string).toLowerCase();

    return {
      status: this.selectedLocation!.active ? inactive : active,
    };
  }

  private get tableHeaders(): any {
    return [
      {text: this.$t('LOCATIONS_OVERVIEW.TABLE.PROJECT_ID'), value: 'id', width: '3'},
      {text: this.$t('LOCATIONS_OVERVIEW.TABLE.NAME'), value: 'name', width: '2'},
      {text: this.$t('LOCATIONS_OVERVIEW.TABLE.ADDRESS'), value: 'address', width: '3'},
      {text: this.$t('LOCATIONS_OVERVIEW.TABLE.CUSTOMER'), value: 'customer', width: '2'},
      {text: this.$t('GENERAL.STATUS'), value: 'active', width: '1', class: 'd-flex justify-center'},
      {text: '', value: 'actions', width: '5%', class: 'd-flex justify-start'},

    ];
  }

  public onLocationDeleteClick(location: Location) {
    this.showDeleteDialog = true;
    this.selectedLocation = location;
  }

  public async onLocationDelete(location: Location) {
    this.showDeleteDialog = false;
    try {
      await this.deleteLocationAction(location.id!);
      this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_DELETE.SUCCESS');
    } catch (e) {
      switch (e.status) {
        case 409: {
          this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_DELETE.RUNNING');
          break;
        }
        default: this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_DELETE.ERROR');
      }
    }
  }

  public onLocationStatusToggleClick(location: Location) {
    this.showChangeStatusDialog = true;
    this.selectedLocation = location;
  }

  public async onLocationChangeStatus(updatedLocation: Location) {
    this.showChangeStatusDialog = false;
    try {
      updatedLocation.active = !updatedLocation.active;
      await this.setLocationStatusAction({location: updatedLocation});
      this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_EDIT.SUCCESS');
    } catch (e) {
      this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_EDIT.ERROR');
    }
  }

  public onLocationEditClick(location: Location) {
    this.showLocationModal = true;
    this.selectedLocation = location;
  }

  public async onManageLocationFormDone(location: Location) {
    const index = this.locations.findIndex((locationEntry) => locationEntry.id === location.id);
    // this reflects location name change in location lists.
    // else the data has to be loaded again to reflect that change
    this.locations[index] = location;
    // Maybe could work without load...
    await this.updateLocationMutation(location);
  }

  private getColor(color: string): string {
    return this.$colorHandler.getThemeColor(color);
  }

  private onRowClicked(item: Location) {
    this.$router.push({
      name: 'locationDashboard',
      params: {
        tenantId: this.$route.params.tenantId,
        locationId: item.id!,
      },
    });
  }
}
