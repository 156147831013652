


























import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import SideCardComponent from '@/components/shared/SideCard.component.vue';
import LocationTableComponent from '@/components/location/LocationTable.component.vue';
import LocationManageMasterDataComponent from '@/components/location/LocationManageMasterData.component.vue';
import {locationStoreActions, locationStoreGetter} from '@/stores/location.store';
import Location from '@/models/Location';

const LocationStore = namespace('location');

@Component({
  components: {
    SideCardComponent,
    LocationTableComponent,
    LocationManageMasterDataComponent,
    CustomerManageComponent: () => import(
        /* webpackChunkName: "CustomerManageComponent" */
        '@/components/customer/CustomerManage.component.vue'),
    ImportComponent: () => import(
        /* webpackChunkName: "ImportCustomersComponent" */
        '@/components/shared/Import.component.vue'),
    CustomerTableComponent: () => import(
        '@/components/customer/CustomerTable.component.vue'),
    SearchBarComponent: () => import(
        '@/components/shared/SearchBar.component.vue'),
    ToggleButtonGroupComponent: () => import(
        '@/components/shared/ToggleButtonGroup.component.vue'),
  },
})
export default class LocationsOverviewView extends Vue {

  @LocationStore.Action(locationStoreActions.LOAD_LOCATIONS_ACTION)
  private loadLocationsAction!: (payload: { tenantId: string, relations?: string[] }) => Promise<Location[]>;
  @LocationStore.Getter(locationStoreGetter.LOCATIONS)
  private _locations!: Location[];

  public get locations(): Location[] {
    return this._locations;
  }
  private selectedFilter: string = 'all';
  private searchString: string = '';
  private showCreateLocationModal: boolean = false;

  private readonly screenWidth: string = screen.width - 90 - 72 + 'px';

  public get filteredLocations() {
    let filteredLocations: Location[] = this.locations.filter((location) => {
      return location.name && location.name
              .toLowerCase().includes(this.searchString.toLowerCase()) ||
          // compares street with the input of the searchbar
          location.address?.street && location.address?.street
              .toLowerCase().includes(this.searchString.toLowerCase()) ||
          // compares customer name with the input of the searchbar
          location.customer?.name && location.customer.name
              .toLowerCase().includes(this.searchString.toLowerCase()) ||
          // compares projectID with the input of the searchbar
          location.projectId && location.projectId
              .toLowerCase().includes(this.searchString.toLowerCase());
    });

    switch (this.selectedFilter) {
      case 'all':
        break;
      case 'active':
        filteredLocations = filteredLocations.filter((location) => location.active);
        break;
      default:
        filteredLocations = filteredLocations.filter((location) => !location.active);
        break;
    }
    return filteredLocations;
  }

  private async mounted() {
    try {
      await this.loadLocationsAction({tenantId: this.$route.params.tenantId, relations: ['address', 'customer']});
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
    if (new URL(document.URL).searchParams.get('showSC') === '1') {
      this.showCreateLocationModal = true;
    }
  }
}

